import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "홈페이지 방문 감사합니다.",
  "subtitle": "월배요양병원 홈페이지 방문을 감사드립니다.",
  "date": "2022-02-10T00:00:00.000Z",
  "author": "관리자"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`월배요양병원 홈페이지가 리뉴얼 되었습니다.`}</h1>
    <p>{`다양한 정보로 안내해 드리겠습니다. 감사합니다.
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ec3cbe08226de31386a9e5cc26bc7e03/a0850/24hr.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEEAgX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB4d0DRGRf/8QAGBABAQADAAAAAAAAAAAAAAAAAQIAEDH/2gAIAQEAAQUCMugHu//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABgQAAIDAAAAAAAAAAAAAAAAAAABICFB/9oACAEBAAY/AhLSof/EABkQAQADAQEAAAAAAAAAAAAAAAEAETEQQf/aAAgBAQABPyE2gRYWA0lbeIRzn//aAAwDAQACAAMAAAAQj8//xAAWEQEBAQAAAAAAAAAAAAAAAAABEBH/2gAIAQMBAT8QTZ//xAAWEQEBAQAAAAAAAAAAAAAAAAABEBH/2gAIAQIBAT8QHJ//xAAcEAEAAQQDAAAAAAAAAAAAAAABABARMUEhYbH/2gAIAQEAAT8QcxdXEB/adHpH2A3t8yZzk3dP/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "이미지",
            "title": "이미지",
            "src": "/static/ec3cbe08226de31386a9e5cc26bc7e03/b4294/24hr.jpg",
            "srcSet": ["/static/ec3cbe08226de31386a9e5cc26bc7e03/75985/24hr.jpg 150w", "/static/ec3cbe08226de31386a9e5cc26bc7e03/f93b5/24hr.jpg 300w", "/static/ec3cbe08226de31386a9e5cc26bc7e03/b4294/24hr.jpg 600w", "/static/ec3cbe08226de31386a9e5cc26bc7e03/8e1fc/24hr.jpg 900w", "/static/ec3cbe08226de31386a9e5cc26bc7e03/e5166/24hr.jpg 1200w", "/static/ec3cbe08226de31386a9e5cc26bc7e03/a0850/24hr.jpg 4096w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      